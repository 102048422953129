/**
 * splitCard.tsx
 * Airwallex Payment Demo - React Typescript.  Created by Olivia Wei and Josie Ku.
 *
 * airwallex-payment-elements Split Card element integration in React Typescript
 * Comments with "Example" demonstrate how states can be integrated
 * with the element, they can be removed.
 *
 * Detailed guidance here: https://github.com/airwallex/airwallex-payment-demo/blob/master/docs/splitcard.md
 */

import React, { useEffect, useState } from "react";
// STEP #1: At the start of your file, import airwallex-payment-elements package
import {
  createElement,
  loadAirwallex,
  getElement,
  confirmPaymentIntent,
  EventDetail,
} from "airwallex-payment-elements";
import Loading from "components/Loading/loading";
import { useTranslation } from "react-i18next";
import axiosInstance from "utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// Enter your Payment Intent secret keys here
// More on getting these secrets: https://www.airwallex.com/docs/api#/Payment_Acceptance/Payment_Intents/Intro
// const intent_id = "replace-with-your-intent-id";
// const client_secret = "replace-with-your-client-secret";

interface splitCardPage {
  startPayment: boolean;
  totalPrice: number;
  intentId: string;
  clientSecret: string;
  productId?: string;
  setStartPayment?: any;
  setAlert?: any;
  setIsLoading?: any;
  bookingInformation?: any;
}

const Index: React.FC<splitCardPage> = ({
  startPayment,
  setStartPayment,
  setAlert,
  setIsLoading,
  intentId,
  clientSecret,
  productId,
  bookingInformation,
}: any) => {
  // Example: element ready states, controls the display for when elements are successfully mounted
  const [cardNumberReady, setCardNumberReady] = useState<boolean>(false);
  const [cvcReady, setCvcReady] = useState<boolean>(false);
  const [expiryReady, setExpiryReady] = useState<boolean>(false);
  // Example: element validation state, checks if each field is completed by the shopper
  const [cardNumberComplete, setCardNumberComplete] = useState<
    undefined | boolean
  >(false);
  const [cvcComplete, setCvcComplete] = useState<undefined | boolean>(false);
  const [expiryComplete, setExpiryComplete] = useState<undefined | boolean>(
    false
  );
  const navigate = useNavigate();
  // Example: controls submission state
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Example: set error state
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation("checkout");
  useEffect(() => {
    // STEP #2: Initialize Airwallex on mount with the appropriate production environment and other configurations
    loadAirwallex({
      env: "prod", // Can choose other production environments, 'staging | 'demo' | 'prod'
      origin: window.location.origin, // Setup your event target to receive the browser events message
      locale: "zh-HK",
      fonts: [
        {
          src: "https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXWeb-Regular.woff2",
          family: "AxLLCircular",
          weight: "400",
        },
        {
          src: "https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXSub-BoldSubset.woff2",
          family: "AxLLCircular",
          weight: "700",
        },
      ],
      // For more detailed documentation at https://github.com/airwallex/airwallex-payment-demo/tree/master/docs#loadAirwallex
    }).then(() => {
      // STEP #4, 5: Create and mount the individual card elements
      createElement("cardNumber")?.mount("cardNumber"); // This 'cardNumber' id MUST MATCH the id on your cardNumber empty container created in Step 3
      createElement("cvc")?.mount("cvc"); // Same as above
      createElement("expiry")?.mount("expiry"); // Same as above
    });

    // STEP #7: Add an event handler to ensure the element is mounted
    const onReady = (event: CustomEvent): void => {
      const { type } = event.detail as EventDetail;
      if (type === "cardNumber") {
        setCardNumberReady(true);
      }
      if (type === "cvc") {
        setCvcReady(true);
      }
      if (type === "expiry") {
        setExpiryReady(true);
      }
    };

    // STEP #8: Add an event listener to listen to the changes in each of the input fields
    const onChange = (event: CustomEvent) => {
      const { type, complete } = event.detail as EventDetail;
      if (type === "cardNumber") {
        setCardNumberComplete(complete);
      }
      if (type === "cvc") {
        setCvcComplete(complete);
      }
      if (type === "expiry") {
        setExpiryComplete(complete);
      }
    };

    window.addEventListener("onReady", onReady as EventListener);
    window.addEventListener("onChange", onChange as EventListener); // Can also using onBlur
    return () => {
      window.removeEventListener("onReady", onReady as EventListener);
      window.removeEventListener("onChange", onChange as EventListener);
    };
  }, []); // This effect should ONLY RUN ONCE as we do not want to reload Airwallex and remount the elements
  useEffect(() => {
    if (startPayment == true) {
      setIsSubmitting(true); // Example: set loading state
      setErrorMessage(""); // Example: reset error message
      const cardNumber = getElement("cardNumber");

      if (cardNumber) {
        confirmPaymentIntent({
          element: cardNumber, // Only need to submit CardNumber element
          id: intentId,
          client_secret: clientSecret,
          // payment_method?  Add other payment confirmation details, see docs here: https://github.com/airwallex/airwallex-payment-demo/tree/master/docs
          payment_method_options: {
            card: {
              auto_capture: true,
            },
          },
        })
          // STEP #6b: Listen to the request response
          .then(async (response: any) => {
            if (response?.status == "SUCCEEDED") {
              try {
                const TransactionRes = await axiosInstance.post(
                  "/api/v1/payments/save-transaction",
                  {
                    paymentId: response?.id,
                    currency: response?.currency,
                    amount: response?.amount,
                    status: "succeeded",
                    description: `Online Payment #${response?.id} (Credit Card)`,
                    paymentDate: moment(response?.created_at).format(
                      "YYYY-MM-DD"
                    ),
                    bookingId: response?.merchant_order_id,
                    paymentType: "rentalfee",
                    paymentMethod: "card",
                  }
                );

                const res = await axiosInstance.post(
                  `/api/v1/bookings/${productId}`,
                  Object.assign({
                    ...bookingInformation,
                    paymentStatus: "SUCCEEDED",
                    payment: TransactionRes?.data?._id,
                  })
                );
                setIsSubmitting(false); // Example: reset loading state

                setStartPayment(false);
                if (res?.status === 200) {
                  setTimeout(() => {
                    setAlert({
                      show: true,
                      message: res?.data?.message,
                      type: "success",
                    });
                  });
                  setIsLoading(false);
                  localStorage.removeItem("selectedCarState");
                  setTimeout(() => {
                    navigate(
                      `/pay-done?bookingId=${res.data.bookingId}&key=${res.data.secretKey}`
                    );
                  }, 2000);
                }
              } catch (error: any) {
                setAlert({
                  show: true,
                  message: error?.response?.data?.message,
                  type: "error",
                });
                setIsLoading(false);
                setIsSubmitting(false); // Example: reset loading state
                setStartPayment(false);
              }
            } else {
              setAlert({
                show: true,
                message: t("CONTACTADMIN", "請聯絡客服人員!"),
                type: "error",
              });
            }
          })
          // STEP #6c: Listen to errors
          .catch((error) => {
            /**
             * ... Handle event on error
             */
            setIsSubmitting(false); // Example: reset loading state
            setStartPayment(false);
            setIsLoading(false);
            setAlert({
              show: true,
              message: error.message ?? JSON.stringify(error),
              type: "error",
            });
            setTimeout(() => {
              setAlert({ show: false, message: "", type: "" });
            }, 5000);

            // setErrorMessage(); // Example: set error message
            console.error("There is an error", error);
          });
      }
    }
  }, [startPayment]);

  // Example: combine all element ready states
  const allElementsReady = cardNumberReady && cvcReady && expiryReady;
  // Example: combine all element complete states
  const allElementsComplete =
    cardNumberComplete && cvcComplete && expiryComplete;

  // Example: Custom styling for the inputs, can be placed in css
  const inputStyle = {
    border: "1px solid",
    borderRadius: "5px",
    padding: "5px 10px",
    marginTop: "8px",
    marginBottom: "15px",
    height: "40px",
  };

  return (
    <div>
      {/* Example below: show loading state */}
      {!allElementsReady && <Loading />}
      {/* Example below: display response message block */}
      {/* {errorMessage.length > 0 && <p id="error">{errorMessage}</p>} */}
      {/* Styling example below: only displays block when all elements are ready */}
      <div style={{ display: allElementsReady ? "block" : "none" }}>
        {/* STEP #3a: Add empty containers for the card elements to be placed into */}
        <div className="field-container">
          <div className="field-label">{t("CARDNUMBER", "Card number")}</div>
          <div
            id="cardNumber"
            style={inputStyle} // Example: input styling can be moved to css
          />
        </div>
        <div className="field-container">
          <div className="field-label">{t("EXPIRYDATE", "Expiry Date")}</div>
          <div
            id="expiry"
            style={inputStyle} // Example: input styling can be moved to css
          />
        </div>
        <div className="field-container">
          <div className="field-label">Cvc</div>
          <div
            id="cvc"
            style={inputStyle} // Example: input styling can be moved to css
          />
        </div>
        {/* STEP #3b: Add a submit button to trigger the payment request */}
        {/* <ButtonPrimary
          className="mt-10"
          onClick={handleConfirm}
          disabled={!allElementsComplete || isSubmitting} // Prevents invalid submissions
        >
          {isSubmitting ? "Loading" : "Confirm and Pay"}
        </ButtonPrimary> */}
      </div>
    </div>
  );
};

export default Index;
